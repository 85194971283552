<template>
    <!-- 云市场 -->
<div class="plugin-bazaar" style="margin-bottom:40px" v-if="pluginState.info">
    <a-carousel class="swiper">
        <div><img :src="pluginState.info.index_banner" alt=""></div>
    </a-carousel>

    <a-row :gutter="[16,16]">
        <a-col :span="16">
            <div class="pb-title"><img :src="$util.getStaticSrc('adopt/b-5.png')" alt="">营销插件 </div>
            <div class="pb-plugins">
                <div class="pb-plugins-item"
                     v-for="(item,index) in pluginState.info.list"
                     :key="index"
                     @click="toPluginDetail(item.plugin_id)"
                >
                    <div class="pbpi-status" v-if="item.is_enable">已开通</div>
                    <img :src="item.detail.plugin_logo" alt="">
                    <div class="pbpi-right">
                        <div>
                            <span class="name">{{ item.detail.plugin_name }}</span>
                        </div>
                        <div class="desc">{{  item.detail.plugin_desc  }}</div>
                        <template v-if="!item.is_enable">
                            <div class="price" v-if=" item.detail.amount_actual > 0">
                                ￥{{  item.detail.amount_actual }}
                            </div>
                            <div class="price" v-else>免费</div>
                        </template>
                    </div>
                </div>
            </div>
        </a-col>
        <a-col :span="8">
            <div class="pb-title"><img :src="$util.getStaticSrc('adopt/b-7.png')" alt="">销量排行 </div>
            <div class="pb-rank">
                <img class="pbr-banner" :src="pluginState.info.top_banner" alt="">
                <div class="pb-rank-list">
                    <div class="pbr-item" v-for="(item,index) in pluginState.info.topList" :key="index">
                        <div class="rank">
                            <img v-if="index == 0" src="../../../assets/img/num1.png" alt="">
                            <img v-if="index == 1" src="../../../assets/img/num2.png" alt="">
                            <img v-if="index == 2" src="../../../assets/img/num3.png" alt="">
                            <span v-if="index >=3 ">{{ index+1 }}</span>
                        </div>
                        <img class="plugin-logo" :src="item.detail.plugin_logo" alt="">
                        <div class="plugin-info">
                            <div class="name">{{ item.detail.plugin_name }}</div>
                            <div class="desc">{{  item.detail.plugin_desc  }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!--            <div class="pb-faceback">-->
            <!--                <span class="title">意见反馈</span>-->
            <!--                <span class="txt">提交需求/建议<i class="ri-arrow-right-s-line"></i></span>-->
            <!--            </div>-->
        </a-col>
    </a-row>
</div>
</template>

<script>
import { usePlugin } from '@/models/plugin'
import router from "@/router";

export default{
    setup() {
        let { pluginState } = usePlugin()

        function toPluginDetail(id){
            router.push({path:'/plugin/detail',query:{id}})
        }
        return{
            pluginState,
            toPluginDetail
        }
    },
}
</script>

<style lang="scss">
// 插件中心
.plugin-bazaar{

    .swiper{
        width: 100%;
        height: 400px;
        margin-bottom: 24px;

        img{
            width: 100%;
            height: 400px;
        }
    }
    .pb-title{
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        font-size: 17px;
        margin-bottom: 12px;

        img{
            width: 20px;
            margin-right: 12px;
            position: relative;
            top: -1px;
        }
    }
    .pb-plugins{
        background: #fff;
        min-height: 630px;
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        overflow: hidden;
        overflow-y: auto;

        &-item{
            width: 245px;
            border: 1px solid #F3F3F3;
            border-radius: 8px;
            display: flex;
            padding: 12px;
            align-items: center;
            height: 100px;
            margin: 0 12px 12px 0;
            cursor: pointer;
            position: relative;

            img{
                width: 72px;
                height: 72px;
                border-radius: 50%;
                margin-right: 16px;
            }
            .name{
                font-size: 15px;
                font-weight: bold;
            }
            .status{
                color: red;
                margin-left: 6px;
                font-size: 12px;
            }
            .desc{
                font-size: 12px;
                color: #999;
                margin: 6px 0;
            }
            .price{
                font-weight: bold;
                font-size: 16px;
                color: red;
            }
        }

        .pbpi-status{
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            background: #00CC99;
            color: #fff;
            font-size: 12px;
            padding: 0 6px;
            border-radius: 0 6px 0 6px ;
        }
    }
    .pb-rank{
        background: #fff;

        .pbr-banner{
            width: 100%;
        }

        &-list{
            width: 100%;
            height: 380px;
            border-radius: 12px;
            position: relative;
            top: -12px;
            background: #fff;
            padding-top: 8px;
        }
        .pbr-item{
            display: flex;
            height: 60px;
            align-items: center;
            margin-top: 12px;
            .rank{
                width: 40px;
                text-align: center;
                margin-left: 24px;

                img{
                    width: 40px;
                }
                span{
                    font-weight: bold;
                    font-size: 18px;
                }
            }

            .plugin-logo{
                width: 56px;
                height: 56px;
                border-radius: 50%;
                margin:  0 24px;
            }
            .name{
                font-weight: bold;
                font-size: 16px;
            }
            .desc{
                color: #999;
                font-size: 12px;
                margin-top: 8px;
            }

        }
    }

    .pb-faceback{
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        margin-top: 16px;
        padding: 0 24px;
        .title{
            font-weight: bold;
            font-size: 17px;
        }
        .txt{
            color:#1f8cfd;
            font-size: 16px;
        }
    }
}

</style>
