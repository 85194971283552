import {reactive} from "vue";
import pluginModel from "@/api/plugin";
import tool from "@/util/tool";
import programModel from "@/api/saas/app";

//插件列表数据
export function usePlugin(){
    let pluginState = reactive({
        info:null
    })
    getPluginList()
    function getPluginList(){
        pluginModel.getMyPlugin(res=> pluginState.info = res)
    }

    return{ pluginState }
}

//插件详情
export function usePluginDetail(id){
    let pdState = reactive({
        info:null
    })
    pluginModel.getPluginDetail({id},res=>{
        pdState.info = res
    })
    return{ pdState }
}

//插件购买
export function usePluginBuy( state ){
    let pbState = reactive({
        form: {
            pay_type:2,
        },
        order_sn:"",
        show:false,
        wx_pay_qrcode:""
    })

    function createPluginOrder(){
        console.log(state);
        pluginModel.createPluginOrder({
            plugin_id:state.info.plugin_id,
            plugin_name:state.info.plugin_name
        },res=>{
            pbState.order_sn = res.order_sn
            if( res.type == 'create_order'){

                let pay_type = pbState.form.pay_type ==1 ?'wx' :'al'

                pluginModel.pluginOrderPay(res.order_sn,pay_type,pay_res=>{
                    pbState.show = true
                    pbState.wx_pay_qrcode = pay_res.pay
                })
            }else{
                tool.message("插件开通成功")
                pbState.show = false
                refreshPlugin()
            }
        })
    }

    function checkIsPay(){
        pluginModel.pluginOrderCheck(pbState.order_sn,res=>{
            tool.message("插件开通成功")
            refreshPlugin()
        })

    }

    function refreshPlugin(){
        let timer = setTimeout(function (){
            pbState.show = false
            programModel.cloudRefreshCache(()=>{})
            location.reload()
        },1000)
    }

    return{ pbState ,checkIsPay,createPluginOrder,refreshPlugin}
}

