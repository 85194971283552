//插件相关
import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'

class Plugin{

    /**
     * 获取农场已开通和未开通的所有插件信息
     * @param {function} cb
     */
    getMyPlugin(cb){
        $post('getPluginList').then(res=>cb(res.data))
    }

    /**
     * 创建插件订单
     * @param {object} param { plugin_id,plugin_name }
     * @param {function} cb
     */
    createPluginOrder(param,cb){
        $post('addPluginOrder',param).then(res=>{
            if( res.type == 'create_order'){
                cb(res)
                return
            }
            tool.message('开通成功')
            cb(res)
        })
    }

    /**
     * 插件订单支付
     * @param {number} order_sn
     * @param {string} pay_type
     * @param {function} cb
     */
    pluginOrderPay(order_sn,pay_type,cb){
        let return_url = window.location.href
        $post('payPluginOrder',{order_sn,pay_type,return_url}).then(res=>{
            if( pay_type == 'al'){  //支付宝支付
                window.location.href = res.data.pay
                return
            }
            //微信支付
            cb(res.data)
        })
    }


    /**
     * 订单是否支付检测
     * @param {number} order_sn
     * @param {function} cb
     */
    pluginOrderCheck(order_sn,cb){
        $post('checkPluginOrder',{order_sn}).then(res=>{
            if( res.data.is_ok ){
                cb(res.data)
                return
            }
            tool.message("您还未支付！")
        },()=>{})
    }

    /**
     * 获取插件详情
     * @param {Object} param
     * @param {function} cb
     */
    getPluginDetail(param,cb){
        $post("getPluginDetails",param,true,1).then(res=>cb(res.data))
    }
}

const pluginModel = new Plugin()
export default pluginModel
